.mobile-menu{
  position: absolute;
  z-index: 29;
  bottom: -1px;
  left: 0;
  width: 100%;
  background-color: white;
  height: 64px;
  border-top: .5px solid var(--border-color);
   box-shadow: 0 4px 14px 0 rgb(0, 0, 0, 0.08);
   padding: 10px;
  }
  .mobile-wrapper-menu{
  width: 100%;
  display: flex;
  height: 100%;

    
}
.mobile-menu-divider{
  background-color: #EEEEEE;
  width: .5px;
  height: 100%;
}
.mobile-menu-link{
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #A5A3A3;
  font-size: 22px;
}
.mobile-menu-link span{
  font-size: 12px;
}
.mobile-menu-link-active{
  color: var(--accent-color);
  /* color: white; */
}
  