.desktop-header {
    height: 64px;
    min-height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 8%);
    width: 100%;
    max-width: 100vw;
}
.header-logo-container{
    height: 100%;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.desktop-header-info-logo {
    display: flex;
    align-items: center;
}
.desktop-header-info-logo p{
    font-size: 12px;
    color: var(--text-primary);
}
.desktop-header-info-logo p span{
    color: var(--text-muted);
}

.desktop-header-info-data {
    max-width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

/* -------------------------------------------------------------------------- */
/*                                 MOBILE VIEW                                */
/* -------------------------------------------------------------------------- */
@media only screen and (max-width: 1000px) {
  .desktop-header-info-logo{
    flex-grow: 1;

  }
  
}
