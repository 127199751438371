.desktop-subjects-tab{
    background: var(--border-color);
    color: #A5A3A3;
    border-radius: 100vh;
    flex-grow: 1;
    display: flex;
}

.desktop-subjects-tab-button:hover{
  color: var(--accent-color);
}
.desktop-subjects-tab-button{
    font-size: 14px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    text-align: center;
    transition: translate ease 0.3s;
    /* text-transform:  uppercase; */
}

p.desktop-tab-active:hover{
  color: white;
  cursor: default;
}
p.desktop-tab-active{
    border-radius: 100vh;
    background-color: var(--active-actions-panel);
    color: white;
}
/* -------------------------------------------------------------------------- */
/*                                 MOBILE VIEW                                */
/* -------------------------------------------------------------------------- */
@media only screen and (max-width: 1000px) {
  .desktop-subjects-tab {
    height: 34px;
    margin-bottom: 10px;
  }
}