.app-init-loading{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.div-1{
    background-color: #F5A800;
    flex: 1;
    height: 100%;
}
.div-2{
    background-color: #00C4B3;
    flex: 1;
    height: 100%;
}
.div-3{
    background-color: #E31D93;
    flex: 1;
    height: 100%;
}


.animated-div {
    background-image: linear-gradient(to right, transparent 50%, white 50%);
    background-size: 200% 100%;
    background-position: left bottom;
    animation: fillRight 1s forwards;
}

@keyframes fillRight {
    to {
        background-position: right bottom;
    }
}