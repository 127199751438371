.desktop-offer-list-container {
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.input-focus-border {
  border: 1px solid var(--accent-color);
}
.tab-first-row {
  display: flex;
  min-width: 250px;
  gap: 1rem;
}

.desktop-filters-nav {
  display: flex;
  gap: 10px;
  background-color: white;
  height: 34px;
  min-height: 34px;
  max-height: 34px;
  margin-bottom: 10px;
}

.desktop-title-2 {
  background-color: white;
  height: 34px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--accent-light-color);
  z-index: 2;
}
.desktop-title-2-first-col {
  display: flex;
  align-items: center;
}
.desktop-title-2-text {
  font-size: 20px;
  font-weight: bold;
  color: #908c8c;
  padding-left: 10px;
}
.pinned-title-2 {
  position: sticky;
  top: 0;
  cursor: default;
}
.offered-title-sub-2 {
  cursor: default;
  position: sticky;
  top: 33px;
}
.offered-title-2 {
  cursor: default;
  position: sticky;
  top: 0px;
}
.desktop-filters-listing {
  /* position: relative; */
  flex-direction: column;
  /* overflow-y: scroll; */
  display: flex;
  scroll-behavior: smooth;
  /* alto - nav - filterNav - spacing */
  /* height: calc(100vh - 80px - 40px - 40px); */
  /* padding-right: 4px; */
  /* padding-bottom: 35px; */
  flex-grow: 1;
  overflow-y: scroll;
}
.desktop-pinned-subject-list {
  background-color: white;
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.list-subjects-counter {
  /* background-color: var(--accent-color); */
  color: var(--accent-color);
  /* color: white; */
  text-align: center;
  font-weight: bold;
  /* margin-left: 10px; */
  border-radius: 100px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
.go-to-top {
  color: var(--accent-color);
  cursor: pointer;
}
.filters-btn {
  background-color: var(--border-color);
  color: var(--text-primary);
  min-width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100vh;
}
.filters-btn-active {
  background-color: var(--active-actions-panel);
}
.search-and-filter {
  flex-grow: 1;
  display: flex;
  gap: 10px;
  height: 34px;
}

.desktop-offered-subject-list {
  /* width: 100%;
  height: 100%; */
  display: flex;
  flex: 1;
  flex-grow: 1;
  /* border: solid 1px red; */
  display: flex;
  scroll-behavior: smooth;
  padding-bottom: 5px;
  min-height: 1200px;
}

/* -------------------------------------------------------------------------- */
/*                                 MOBILE VIEW                                */
/* -------------------------------------------------------------------------- */
@media only screen and (max-width: 1000px) {
  .desktop-filters-nav {
    width: 100%;
  }
  .search-and-filter {
    flex-grow: 0;
  }
}
