.panel-filters{
    background-color: white;
    /* display: flex;
    flex-direction: column;
    gap: 1rem; */
    width: 25vw;
    max-width: 600px;
    height: calc(100vh - 64px);
    position: absolute;
    top: 64px;
    right: 0;
    z-index: 99;
    box-shadow: -14px 0px 14px rgba(0, 0, 0, 8%);
    padding: 30px 0;
    padding-top: 0;
    overflow-y: scroll;
}
.panel-filters-content{
    width: 100%;
    overflow: hidden;
    position: relative;
    
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.panel-filters-title{
    background-color: white;
    padding: 30px 0 15px 0;
    position: sticky;
    top: 0;
    z-index: 1;

    display: flex;
    justify-content: space-between;
    align-items: center;
}
.panel-filters-title-name{
    font-size: 16px;
    font-weight: bold;
}
.panel-filter-item{
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}
.panel-filter-item p{
 font-size: 14px;
}
.panel-checkbox{
    color: var(--accent-color);
}
.panel-checkbox-active{
    color: var(--accent-color);
}
.panel-filters-select-all{
    cursor: pointer;
}
.panel-filters-select-all-active{
    color: var(--accent-color);
}

/* -------------------------------------------------------------------------- */
/*                                 MOBILE VIEW                                */
/* -------------------------------------------------------------------------- */
@media only screen and (max-width: 1000px) {
  .panel-filters{
   width: 100%;
  }
}