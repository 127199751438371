 .warn-border.shoelace-modal::part(body) {
  border-top: 10px solid #F5A800;
}
 .success-border.shoelace-modal::part(body) {
  border-top: 10px solid var(--accent-color);
}
.shoelace-modal::part(body) {
    position: relative;
    overflow: hidden;
}

.custom-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    gap: 18px;
}
.custom-modal-submessage{
  font-size: 14px;
}
/* .custom-modal::after {
    content: "";
    width: 100%;
    height: 10px;
    background-color: var(--accent-color);
    position: absolute;
    top: 0;
} */

.custom-modal-message {
    color: var(--text-primary);
    font-weight: bold;
    font-size: 16px;
    /* width: 30ch; */
}

.custom-modal-actions {
    display: flex;
    justify-content: center;
    gap: 10px;
    font-size: 14px;
}

.custom-modal-actions-button-ok {
    background-color: white;
    font-weight: bold;
    border-width: 2px;
    padding: 0 10px;
    border-radius: 6px;
    min-width: 150px;
  }
  
  .custom-modal-actions-button-cancel {
    border-radius: 6px;
    background-color: transparent;
    border: 0;
    color: white;
    padding: 8px 13px;
    font-weight: bold;
    min-width: 150px;
}