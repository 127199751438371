.desktop-inscribed-subjects {
  padding: 10px 0 30px 0;
  flex-grow: 1;
  height: 100%;
}

.desktop-inscribed-subjects-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  overflow-y: scroll;
  position: relative;
}

.desktop-inscribed-subjects-count {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 0;
}

.selected-subjects-button-text {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 8px;
}

.desktop-inscribed-subject-item {
  background-color: white;
  width: 100%;
  display: flex;
  gap: 20px;
  padding: 10px 0;
  color: #424242;
  font-size: 12px;
}

.desktop-selected-subjects-item-left {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.desktop-selected-subject-item-left-title:hover {
  text-decoration: underline;
  cursor: pointer;
}
.desktop-selected-subject-item-left-title {
  font-size: 14px;
}
.desktop-btn-unsuscribe-subject:hover {
  background-color: #bd8202;
}
.desktop-btn-unsuscribe-subject:active {
  background-color: #f5a800;
}
.desktop-btn-unsuscribe-subject:disabled {
  cursor: not-allowed;
}
.desktop-btn-unsuscribe-subject {
  width: 160px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  background-color: #f5a800;
  color: white;
  border: none;
  font-size: 16px;
  font-weight: bold;
  height: 32px;
  padding: 0 15px;
  border-radius: 6px;
  cursor: pointer;
}

.desktop-item-wrapper {
  width: 100%;
  padding-right: 4px;
   /* border-bottom: 0.5px solid var(--accent-color-bg); */
}
.desktop-btn-unsuscribe-subjec:disabled{
  background-color: var(--text-muted);
  cursor:not-allowed;
}

.selected-subject-obs{
  font-size: 12px;
  display: flex;
  align-items: center;
  padding-bottom: 4px;
}
.selected-subject-obs .subject-cant-creditos{
  color: var(--text-muted);
  width: 150px;
  text-align: right;
  margin-left: auto;
}
.selected-subject-obs .subject-cant-creditos span{
  color: var(--text-primary);
}

.total-creditos-abogacia{
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--border-color);
  margin-top: auto;
  padding-top: 10px;
  width: 100%;
  font-weight: bold;
  color: var(--text-muted);
  font-size: 14px;
}
.total-creditos-abogacia h6{
  color: var(--text-primary);
  padding: 0 20px;
}

/* -------------------------------------------------------------------------- */
/*                                 MOBILE VIEW                                */
/* -------------------------------------------------------------------------- */
@media only screen and (max-width: 1000px) {
  .mobile-no-text {
    display: none;
  }
  .desktop-btn-unsuscribe-subject {
  width: auto;
  }
  .desktop-inscribed-subjects-list {
    scrollbar-width: none;
  }

 
}