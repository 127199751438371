.mobile-layout {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.mobile-layout-content {
    flex: 1;
    overflow-y: auto;
    padding: 12px 12px 60px 12px;

}

.mobile-layout-header {
    height: fit-content;
}

.mobile-layout-navbar {
    /* height: 11%; */
    min-height: 90px;
}