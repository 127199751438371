@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "RobotoBold";
  src: local("RobotoBold"),
   url("./assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
 }

 @font-face {
  font-family: "RobotoRegular";
  src: local("RobotoRegular"),
   url("./assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 300;
 }

 @font-face {
  font-family: "MontserratBold";
  src: local("MontserratBold"),
   url("./assets/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: bold;
 }

 @font-face {
  font-family: "MontserratRegular";
  src: local("MontserratRegular"),
   url("./assets/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: 300;
 }

 body, html, #root {
  height: 100%;
 }

 body {
  margin: 0;
  font-family: 'RobotoRegular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-primary)
}

:root {
  --text-primary: #424242;
  --text-disabled: #B7B7B7;
  --text-accent: #00998C;
  --text-muted: #B7B7B7;
  --border-color: #EEEEEE;
  --active-actions-panel:#656464;
  --light-gray: #EEEEEE;
  --light-gray-cardBg: #F2F2F2;
  --light-gray-cardBorder: #A5A3A3;
  --accent-color: #00C4B3;
  --accent-color-darken: #019b8e;
  --accent-color-bg: #ECF4F4;
  --accent-color-150: #00c4b42d;
  --accent-light-color: #ccebe8;
  --expand-animation-timing: ease;
  --expand-animation-length: 400ms;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* SCROLL ------------------------*/
*::-webkit-scrollbar {
  height: 12px;
  width: 5px;
}
*::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: #DFE9EB;
}

*::-webkit-scrollbar-track:hover {
  background-color: #d4dbdd;
}

*::-webkit-scrollbar-track:active {
  background-color: #d4dbdd;
}

*::-webkit-scrollbar-thumb {
  border-radius: 20px;
  /* background-color: #00c4b3; */
  background-color: #aaaaaa;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #2cc2b5;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #14a79a;
}

.Toastify__toast-icon{
  width: 30px!important;
  height: 30px!important;
  
}