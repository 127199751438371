.search-input::part(base) {
    background-color: var(--light-gray);
    border: 0;
}
.search-container{
    width: 100%;
    height: 100%;
    background-color: var(--light-gray);
    display: flex;
    align-items: center;
    gap: .5rem;
    border-radius: 200px;
    padding: 0 8px;
    position: relative;
    
}

.search-input{
    width: 100%;
    height: 100%;
    background: transparent;
    outline: none;
    border: none;
}

.search-input::part(prefix) {
    padding-left: 18px;
}

.clear-search{
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  background-color: var(--light-gray);
  border-radius: 100vh;
  position: absolute;
  right: 0;
outline: none;
cursor: pointer;
}
.search-icons{
    font-size: 18px;
}

/* -------------------------------------------------------------------------- */
/*                                 MOBILE VIEW                                */
/* -------------------------------------------------------------------------- */
@media only screen and (max-width: 1000px) {
  .search-container {
    height: 34px;
  }
  .mobile-search-wrapper{
    width: 34px;
  }
  .mobile-search-wrapper{
    width: 100%;
    position: absolute;
    left: 0;
  }
  .mobile-search-hide{
  display: none;
}
}