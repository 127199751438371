#flexcalendar{
    box-sizing: border-box;
}
.flexcalendar-container{
    height: 100%;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    /* border: 1px solid red; */
  }
  .flexcalendar-dias{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    position: sticky;
    top: -1px;
    margin-left: auto;
    z-index: 59;
    /* border: 1px solid red; */
    width: 100%;
    padding-left: 5%;
}
.flexcalendar-dias h4{
    text-align: center;
font-size: 14px;
padding: 16px 0 10px 0;
color: black;
font-weight: bold;
flex: 1;
}
.flexcalendar-horarios{
    /* border: 1px solid red; */
    height: 100%;
    width: 100%;
    position: absolute;
}
.flexcalendar-franjas-horarias{
    height: 100%;
    width: 100%;
    position: absolute;
}
.flexcalendar-horarios-row{
    border-top: 1px solid var(--border-color);
}
.flexcalendar-horarios-row-title{
    /* background: #eee; */
    font-size: 9px;
    color: #ACACAC;
}


.flexcalendar-cursadas{
    /* background: rgba(0, 128, 0, 0.3); */
    height: 100%;
    width: 95%;
    right: 0;
    padding-right: 4px;
    position: absolute;

    color: black;
    display: flex;
    justify-content: space-between;
    gap: 5px;
    font-size: 10px;

}
.flexcalendar-col-divider{
    height: calc(60px*16);
    width: 1px;
    background-color: var(--border-color);
}
.flexcalendar-cursadas-col{
/* border: 1px solid black; */
/* border-right: 1px solid gray; */
flex: 1;

position: relative;
}

.flexcalendar-subject-card{
    border: 1px solid black;
    border-left: 5px solid black;
    border-bottom: 2px solid black;
    background: white;
    border-radius: 6px;
    height: 120px;
    padding: 5px;
    overflow: hidden;
    cursor: pointer;
    line-height: .9rem;
    display: flex;
    flex-direction: column;
    
    position: absolute;
    width: 100%;
    right: 0;
}


.flexcalendar-subject-card h5{
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
}
.flexcalendar-subject-card p{
  font-size: 12px;
}

.flexcalendar-btn-clear:hover{
  background-color: #b0f5ef;
}
.flexcalendar-btn-clear:active{
  background-color: white;
}
.flexcalendar-btn-clear{
    background-color: white;
    border: 2px solid var(--accent-color);
    /* color: white; */
    border-radius: 100vh;
    padding: 3px;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;

    position: sticky;
}
.flexcalendar-btn-individual-day{
    background-color: white;
    border: 2px solid var(--accent-color);
    /* color: white; */
    border-radius: 100vh;
    padding: 4px;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;

    position: sticky;
}


.flexcalendar-warn-icon{
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #E31616;
    color: white;
    font-size: 16px;
    border-radius: 100vh;
    padding: 1px;
    margin-bottom: 3px;
}
.flexcalendar-subject-card:hover>.flexcalendar-warn-icon{
  right: 0!important;
}

/* -------------------------------------------------------------------------- */
/*                                 MOBILE VIEW                                */
/* -------------------------------------------------------------------------- */
@media only screen and (max-width: 1000px) {
 .flexcalendar-container{
  scrollbar-width: none;
  scroll-behavior: smooth;
 }
 
}
@media only screen and (min-width: 1000px) {
.flexcalendar-subject-card:hover{
  min-height: 120px!important;
  width: 140%!important;
  z-index: 3;
  box-shadow: 2px 4px 6px 0 rgb(0, 0, 0, 0.3); 
}
 
}

