.page{
    height: 100vh;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.row{
    width: 100%;
    max-width: 270px;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.input-test{
    flex-grow: 1;
}
.full-width{
    width: 100%;
}

.text-center{
    text-align: center;
}
.container-multi-carrera{
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-height: 400px;
    width: 100%;
}
.carrera-container-select{
    cursor: pointer;
    border-left: 5px solid green;
    padding: 30px;
    width: 100%;
    border-radius: 6px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 8%);
    text-align: left;
}