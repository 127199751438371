.select-carrera-container{
    border-left: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 15px;
    padding: 0 20px;
    /* max-width: 300px; */
    flex-grow: 1;
  }
  .select-carrera-container p{
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    font-size: 16px;
    width: 100%;

    /* cortar en segunda linea maximo */
    display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}
.selected-carrera-item{
    font-weight: bold;
    padding-right: 10px;
}

/* -------------------------------------------------------------------------- */
/*                                 MOBILE VIEW                                */
/* -------------------------------------------------------------------------- */
@media only screen and (max-width: 1000px) {

 
}