.mobile-nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
}

.mobile-inscripciones-title{
    font-weight: bold;
    font-size: 1.5rem;
    color: var(--text-primary);
}
.mobile-nav-link{
  color: var(--accent-color);
  text-decoration: underline;
  display: flex;
  align-items: center;
  gap: 10px;
}
.mobile-nav-link span{
  text-decoration: none;
  background-color: var(--accent-color);
  color: white;
  display: inline-block;
  text-align: center;
  min-width: 24px;
  min-height: 24px;
  border-radius: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}