.desktop-subject-item-container {
  display: flex;
  height: 100%;
  padding: 5px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.desktop-subject-item {
  width: 99%;
  height: 100%;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgb(0, 0, 0, 0.05);
  /* align-self: center;
  justify-self: center; */
  /* min-height: 73px; */
}
.desktop-item-first-row {
  display: flex;
  align-items: center;
  gap: 15px;
}

.desktop-item-second-row {
  display: flex;
  gap: 20px;
}

.desktop-inner-col {
  font-size: 14px;
}
.desktop-inner-col:first-child {
  width: 200px;
  max-width: 230px;
}
.desktop-inner-col:last-child {
  flex-grow: 1;
  text-align: right;
}
.desktop-subject-type {
  color: var(--text-muted);
}
.desktop-icon-user-quota {
  font-size: 16px;
}

.desktop-item-title {
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}
.desktop-item-section {
  white-space: nowrap;
  overflow: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
  /* width: 200px; */
  width: max-content;
  height: fit-content;
}
.desktop-item-title:hover {
  text-decoration: underline;
}

.desktop-items-actions {
  display: flex;
  align-items: center;
  gap: 18px;
}

.item-actions-circles {
  display: flex;
  align-items: center;
  gap: 11px;
}

.desktop-action {
  border-radius: 100vh;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--accent-color);
  font-size: 15px;
  border: 2px solid var(--accent-color);
  background-color: transparent;
  cursor: pointer;
}
.desktop-action:hover {
  background-color: var(--accent-color-150);
}
.desktop-action:active {
  background-color: white;
}

.spinner-animation {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.desktop-action-add:hover {
  background-color: var(--accent-color-darken);
}
.desktop-action-add:active {
  background-color: var(--accent-color);
}
.desktop-action-add {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: var(--accent-color);
  color: white;
  border: none;
  width: 125px;
  font-size: 16px;
  font-weight: bold;
  height: 32px;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
}

.desktop-pinned-subject {
  border: 2px solid var(--accent-color);
  background-color: var(--accent-color-bg);
}

.desktop-pinned-subject .btn-pin {
  background-color: var(--accent-color);
  color: white;
}

.desktop-pinned-subject .btn-pin:hover {
  background-color: var(--accent-color-darken);
  border-color: var(--accent-color-darken);
}

.desktop-items-actions .btn-calendar-active {
  background-color: var(--accent-color);
  color: white;
}
.mobile-items-actions .btn-calendar-active {
  background-color: var(--accent-color);
  color: white;
}
.desktop-items-actions .btn-calendar:hover {
  background-color: var(--accent-color-darken);
  border-color: var(--accent-color-darken);
}

.calendar-loading-icon {
  background: linear-gradient(
    to right,
    var(--accent-color) 0%,
    var(--accent-color) 50%,
    transparent 50%,
    transparent 100%
  );
  background-size: 200% 100%;
  animation: fill 400ms forwards;
}

@keyframes fill {
  to {
    background-position: -100% 0;
  }
}

.desktop-action-add-disabled {
  background-color: var(--text-muted);
}

.desktop-action-add-disabled:hover {
  background-color: var(--text-muted);
  animation: none;
  cursor: not-allowed;
}
.desktop-action-add-disabled:active {
  background-color: var(--text-muted);
  animation: none;
  cursor: not-allowed;
}

.subject-item-type {
  background-color: var(--text-muted);
  color: white;
  font-size: 12px;
  padding: 2px 7px;
  border-radius: 100vh;
}
.desktop-action-add-noquota {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: var(--border-color);
  color: var(--text-primary);
  border: none;
  width: 125px;
  font-size: 16px;
  font-weight: bold;
  height: 32px;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  border: 1px solid var(--text-primary);
  cursor: not-allowed;
}
.bg-gray-noquota {
  background-color: var(--light-gray-cardBg);
  border-color: var(--light-gray-cardBorder);
}

.desktop-icon-user-quota-hover:hover {
  background-color: var(--border-color);
  border: 1px solid var(--text-muted);
}

.hidden-card {
  display: none;
}
.mobile-items-actions {
  display: none;
}
div.hide-collapse-info {
  display: none;
}
.bottom-car-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.divider-card {
  height: 1px;
  background-color: var(--light-gray);
  width: 100%;
  margin: 12px 0;
}

/* .fill-animation{
  width: 100%;
  height: 100%;
  left: 0%;
  position: relative;
  transition: all .35s;
  position: absolute;
  border: 1px solid var(--accent-color);
}

.fill-animation:after{
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #00c4b400;
  
  border-radius: 0 20px 20px 0;
  transition: all 5s;
}

.fill-animation:hover:after{
  background: #00c4b493;
  width: 100%;
  border-radius: 0;
} */
.animation-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
  padding: 18px 21px;
}
.fill-animation {
  width: 100%;
  height: 100%;
  left: 0%;
  position: absolute;
  border-left: 3px solid var(--accent-color);
  border-radius: 8px;
  overflow: hidden;
  z-index: 0;
}

.fill-animation:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  /* background: #00c4b400; */
  border-radius: 0 20px 20px 0;
  transition: all 5s;
}

.fill-animation:after {
  animation: fillEffect 20s forwards;
}

@keyframes fillEffect {
  0% {
    width: 0;
    background: #00c4b400;
  }
  35% {
    width: 50%; /* Hasta el 50% en 7 segundos (35% de 20s) */
    background: #ccebe8;
  }
  50% {
    width: 70%; /* Del 50% al 70% en 3 segundos más (15% de 20s) */
    background: #ccebe8;
  }
  65% {
    width: 95%; /* Del 70% al 95% en 3 segundos más (15% de 20s) */
    background: #ccebe8;
    transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  }
  100% {
    width: 100%; /* Del 95% al 100% en los 3 segundos restantes */
    background: #ccebe8;
    border-radius: 0;
  }
}
.mobile-profesor-titular {
  display: none;
}
.title-column-card {
  flex-grow: 1;
}
.title-section-wrapper {
  display: flex;
  /* flex:1; */
  /* width: 100%; */
}
.title-section-wrapper .desktop-item-title {
  width: auto;
  margin-right: 3px;
}
.section-part {
  font-size: 1;
  font-size: 18px;
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: default;
}

.tooltip .tooltiptext {
  visibility: hidden;
  padding: 0.25em 0.5em;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 0.25em;
  white-space: nowrap;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 100%;
  transition-property: visibility;
  transition-delay: 0s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  transition-delay: 0.3s;
}
/* cancel animation */
.cancel-animation {
  border-left: 3px solid red;
  border-radius: 8px;
  animation: fill-red 0.5s ease-in-out forwards;
}
@keyframes fill-red {
  0% {
    clip-path: circle(0% at 0% 100%); /* Círculo inicial en la esquina inferior izquierda */
  }
  100% {
    background: rgb(255, 133, 133);
    clip-path: circle(150% at 0% 100%); /* Expandir el círculo para llenar el div */
  }
}
/* -------------------------------------------------------------------------- */
/*                                 MOBILE VIEW                                */
/* -------------------------------------------------------------------------- */
@media only screen and (max-width: 1000px) {
  .desktop-item-title {
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
  .animation-wrapper {
    padding: 15px 20px;
  }
  .desktop-icon-user-quota {
    font-size: 18px;
  }
  .title-column-card {
    overflow: hidden;
    flex-grow: 1;
  }
  .desktop-items-actions {
    display: none;
  }
  .mobile-items-actions {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: end;
  }
  .mobile-profesor-titular {
    display: block;
    font-size: 14px;
  }

  .divider-card {
    display: none;
  }
  .desktop-item-second-row {
    padding: 12px 0;
  }
}
