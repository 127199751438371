.desktop-mensaje-prioridad{
    border: 1px solid #FACF72;
    background-color: #FFF7E5;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    padding: 5px 15px;
    min-height: 48px;
}
.desktop-mensaje-prioridad-icon{
    background-color: #FACF72;
    min-width: 26px;
    min-height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100vh;
}


.desktop-mensaje-prioridad p{
    font-size: 14px;
    flex-grow: 1;
    line-height: normal;
}
.desktop-mensaje-prioridad.prioridad-activa{
    border: 1px solid var(--accent-color);
    background-color: #ECF4F4; 
}
.prioridad-activa .desktop-mensaje-prioridad-icon{
    background-color: var(--accent-color); 
    color: white;
}

/* -------------------------------------------------------------------------- */
/*                                 MOBILE VIEW                                */
/* -------------------------------------------------------------------------- */
@media only screen and (max-width: 1000px) {
  .desktop-mensaje-prioridad {
    margin-bottom: 15px;
  }
  .close-mobile-size {
    font-size: 20px;
  }
  .desktop-mensaje-prioridad p{
    font-size: 13px;
}
}