.desktop-layout {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
}

.desktop-layout-content {
    flex-grow: 1;
    height: calc(100vh - 100px);
    padding-bottom: 20px;
    overflow: hidden;
}