.calendar-image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  height: 100%;
}
.cursos-image,
.empty-calendar-msg {
  width: 80%;
  max-width: 300px;
  text-align: center;
  font-size: .9rem;
  color: var(--text-primary);
}
.cursos-image {
  margin-left: 74px;
}
