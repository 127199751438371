.transition-modal::part(body) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    height: 100vh;
    width: 100vw;
    gap: 20px;
}

.transition-modal::part(panel) {
    background-color: white;
    position: absolute;
    inset: 0;
    height: 100vh;
    max-height: 100%;
    max-width: 100%;
}

.transition-modal-text {
    color: var(--text-primary);
    font-size: 16px;
}