.nav-options-btn{
  border-radius: 100vh;
  background-color: var(--border-color);
  color: var(--text-primary);
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-options-active{
  background-color: var(--active-actions-panel);
  color: white;
}