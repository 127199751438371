.error-screen-container{
   
}
.error-alert-box{
    margin: 30px auto;
    display: flex;
    justify-content: start;
    gap: 2rem;
    width: 90%;
    padding: 30px;
    box-shadow: 0 4px 14px 0 rgb(0, 0, 0, 0.08);
    border-radius: 8px;
    border: 1px solid var(--border-color);
}
.error-alert-box div{
    text-align: left;
}
.error-alert-box div h2{
 font-weight: 600;
 margin-bottom: 15px;
}
.error-alert-icon{
    padding: 5px;
    border-radius: 100vh;
    background-color: #5688BE;
    color: white;
    min-width: 60px;
    height: 60px;
}
