.header-info-data-user {
    display: flex;
    gap: 8px;
    align-items: center;
}

.header-info-data-user-name {
    font-size: 12px;
    color: var(--text-muted);
}

.btn-logout{
    cursor: pointer;
}

.menu-item{
    font-size: 12px;
}
.header-info-data-user-name-circle{
    background-color: var(--text-muted);
    color: white;
    width: 36px;
    height: 36px;
    border-radius: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}